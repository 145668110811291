import React, { useEffect } from "react"
import styled from 'styled-components'
import { graphql, useStaticQuery } from "gatsby"
import Img from 'gatsby-image'
import media from "../../styles/media"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const GalleryCon = styled.div`
  display: none;
  ${media.laptop`
    display: grid;
    grid-template-columns: 0.8fr 0.1fr 0.1fr 0.5fr 0.5fr 0.05fr 0.1fr 0.8fr;
    grid-template-rows: 0.2fr 0.2fr 0.225fr 0.1fr 0.5fr 0.5fr;
    margin: 0 0 20vh;
    .image1 {
      grid-column: 1/2;
      grid-row: 2/6;
      overflow: hidden;
    }
    .image2 {
      grid-column: 3/7;
      grid-row: 1/5;
      overflow: hidden;
    }
    .image3 {
      grid-column: 4/6;
      grid-row: 4/7;
      overflow: hidden;
    }
    .image4 {
      grid-column: 8/9;
      grid-row: 3/6;
      overflow: hidden;
    }
  `}
`

const Gallery = () => {
  const data = useStaticQuery(graphql`
    query {
      gallery1: file(relativePath: { eq: "look1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery2: file(relativePath: { eq: "look2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery3: file(relativePath: { eq: "look3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery4: file(relativePath: { eq: "look4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  // useEffect(() => {
  //   gsap.from(".image1", {
  //     duration: 4, y:"-5vh", ease:"ease-in-out", 
  //     scrollTrigger: {
  //       trigger:".image1",
  //       markers:false,
  //       start:"top 50%",
  //       end:"bottom 0%",
  //       toggleActions:"restart complete reverse reset"
  //     }
  //   })

  //   gsap.from(".image2", {
  //     duration: 5, y:"-5vh", ease:"linear", 
  //     scrollTrigger: {
  //       trigger:".image2",
  //       markers:false,
  //       start:"top 100%",
  //       end:"bottom 0%",
  //       toggleActions:"restart complete reverse reset"
  //     }
  //   })

  //   gsap.from(".image3", {
  //     duration: 2, y:"-5vh", ease:"linear", 
  //     scrollTrigger: {
  //       trigger:".image3",
  //       markers:false,
  //       start:"top 50%",
  //       end:"bottom 0%",
  //       toggleActions:"restart complete reverse reset"
  //     }
  //   })

  //   gsap.from(".image4", {
  //     duration: 3, y:"-5vh", ease:"linear", 
  //     scrollTrigger: {
  //       trigger:".image4",
  //       markers:false,
  //       start:"top 50%",
  //       end:"bottom 0%",
  //       toggleActions:"restart complete reverse reset"
  //     }
  //   })
  // })

  return (
    <GalleryCon>
      <div className="image1">
        <Img
          durationFadeIn={250}
          objectFit="contain"
          fluid={data.gallery1.childImageSharp.fluid}
        />
      </div>
      <div className="image2">
        <Img
          durationFadeIn={250}
          objectFit="contain"
          fluid={data.gallery2.childImageSharp.fluid}
        />
      </div>
      <div className="image3">
        <Img
          durationFadeIn={250}
          objectFit="contain"
          fluid={data.gallery3.childImageSharp.fluid}
        />
      </div>
      <div className="image4">
        <Img
          durationFadeIn={250}
          objectFit="contain"
          fluid={data.gallery4.childImageSharp.fluid}
        />
      </div>
    </GalleryCon>
  )
}

export default Gallery
