import React from "react"
import Gallery from "./gallery"
import Hero from "./hero"
import GalleryMob from "./mobGallery"

const HomePage = () => (
  <>
  <Hero />
  <Gallery />
  <GalleryMob />
  </>
)

export default HomePage
