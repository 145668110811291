import React from "react"
import styled from 'styled-components'
import media from '../../styles/media'
import { Reveal, Tween } from 'react-gsap'

const ContactDeatilsCon = styled.div`
  margin: 5vh 0 10vh;
  display: block;
  grid-template-columns: 1fr 1fr 1fr;
  p {
    font-size: 14px;
    line-height: 25px;
    /* font-weight: 300; */
    a {
      color: #4e4e4e;
      text-decoration: none;
    }
    &.top {
      margin: 0;
      margin-top: 10vh;
      font-family: 'acumin-pro-med';
    }
  }

  ${media.laptop`
    margin: 5vh 5vw 10vh;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    p {
      font-size: 18px;
      line-height: 30px;
      /* font-weight: 300; */
      margin-bottom: 20px;
      &.top {
        margin: 0;
      }
    }
    a {
      transition: 300ms;
      &:hover {
        opacity: 0.5;
      }
    }
  `}
`

const ContactDetails = () => (
  <ContactDeatilsCon>
    <div>
      {/* <Reveal>
        <Tween from={{ opacity: 0, y: 20 }} duration={1} delay={.6}> */}
          <p className="top">General</p>
        {/* </Tween>
        <Tween from={{ opacity: 0, y: 20 }} duration={1} delay={.8}> */}
          <p><a href="mailto:info@itshome.co.za" target="_blank" rel="noreferrer">info@itshome.co.za</a></p>
        {/* </Tween>
        <Tween from={{ opacity: 0, y: 20 }} duration={1} delay={1}> */}
          <p><a href="tel:+27614967633" target="_blank" rel="noreferrer">+27 61 496 7633</a></p>
        {/* </Tween>
      </Reveal> */}
    </div>
    <div>
      {/* <Reveal>
        <Tween from={{ opacity: 0, y: 20 }} duration={1} delay={.6}> */}
          <p className="top">Address</p>
        {/* </Tween>
        <Tween from={{ opacity: 0, y: 20 }} duration={1} delay={.8}> */}
          <p>1 Broadacres Drive, Fourways</p>
        {/* </Tween>
        <Tween from={{ opacity: 0, y: 20 }} duration={1} delay={1}> */}
          <p>Sandton, 2191</p>
        {/* </Tween>
      </Reveal> */}
    </div>
    <div>
      {/* <Reveal>
        <Tween from={{ opacity: 0, y: 20 }} duration={1} delay={.6}> */}
          <p className="top">Follow us</p>
        {/* </Tween>
        <Tween from={{ opacity: 0, y: 20 }} duration={1} delay={.8}> */}
          <p><a href="https://www.facebook.com/itshome.co.za" target="_blank" rel="noreferrer">Facebook</a></p>
        {/* </Tween>
        <Tween from={{ opacity: 0, y: 20 }} duration={1} delay={1}> */}
          <p><a href="https://www.instagram.com/itshome.co.za/" target="_blank" rel="noreferrer">Instagram</a></p>
        {/* </Tween>
      </Reveal> */}
    </div>
  </ContactDeatilsCon>
)

export default ContactDetails
