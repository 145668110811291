import React, { useEffect, useRef } from "react"
import styled from 'styled-components'
import media from '../../styles/media'
import { TweenMax, Power3 } from 'gsap'
import { HeadText } from "../../styles"
import * as Scroll from "react-scroll"
import { SplitChars, Tween } from 'react-gsap'

const HeroCon = styled.div`
  margin: 10vh 0vw 5vh;
  p {
    &.top {
      font-size: 14px;
      margin: 0;
    }
    font-size: 14px;
    line-height: 25px;
    /* font-weight: 300; */
    margin-top: 40px;
  }

  /* .heading {
    display: none;
  }

  h1 {
    display: none;
  } */

  ${media.laptop`
    margin: 18vh 5vw 20vh;
    text-align: center;
    p {
      /* opacity: 0; */
      font-size: 21px;
      line-height: 35px;
      /* font-weight: 300; */
      margin: 50px 12vh 0;
      &.top {
        font-size: 14px;
        margin: 0;
      }
    }
  `}
`

const Hero = () => {
  const miniRef = useRef(null)
  // const headingRef = useRef(null)
  const introRef = useRef(null)

  const Element = Scroll.Element;

  // useEffect(() => {
  //   TweenMax.to( 
  //     miniRef.current, 1, {
  //       opacity: 1,
  //       y: -20,
  //       ease: Power3.easeOut,
  //       delay: 0
  //     }
  //   )
  // }, [])

  // useEffect(() => {
  //   TweenMax.to( 
  //     headingRef.current, 1, {
  //       opacity: 1,
  //       y: -20,
  //       ease: Power3.easeOut,
  //       delay: .4
  //     }
  //   )
  // }, [])

  // useEffect(() => {
  //   TweenMax.to(
  //     introRef.current, 1, {
  //       opacity: 1,
  //       y: -20,
  //       ease: Power3.easeOut,
  //       delay: .8
  //     }
  //   )
  // }, [])

  return (
    <Element name="home">
      <HeroCon>
        <div className="text">
          <p className="top" ref={miniRef}>WELCOME TO ITS HOME</p>
          <HeadText>Making spaces beautiful through meaningful design</HeadText>
          {/* <Tween to={{ display: 'inline-flex' }} delay={.04}>
            <HeadText>
              <Tween from={{ opacity: 0, y: 20 }} stagger={0.04} ease="elastic.out(0.1, 0.1)" delay={.04}>
                <SplitChars
                  wrapper={<div style={{ display: 'inline-block'}} />}
                >
                  Making&nbsp;
                </SplitChars>
              </Tween>
            </HeadText>
            <HeadText>
              <Tween from={{ opacity: 0, y: 20 }} stagger={0.04} ease="elastic.out(0.1, 0.1)" delay={0.32}>
                <SplitChars
                  wrapper={<div style={{ display: 'inline-block'}} />}
                >
                  spaces&nbsp;
                </SplitChars>
              </Tween>
            </HeadText>
            <HeadText>
              <Tween from={{ opacity: 0, y: 20 }} stagger={0.04} ease="elastic.out(0.1, 0.1)" delay={0.56}>
                <SplitChars
                  wrapper={<div style={{ display: 'inline-block'}} />}
                >
                  beautiful&nbsp;
                </SplitChars>
              </Tween>
            </HeadText>
            <HeadText>
              <Tween from={{ opacity: 0, y: 20 }} stagger={0.04} ease="elastic.out(0.1, 0.1)" delay={0.92}>
                <SplitChars
                  wrapper={<div style={{ display: 'inline-block'}} />}
                >
                  through&nbsp;
                </SplitChars>
              </Tween>
            </HeadText>
            <HeadText>
              <Tween from={{ opacity: 0, y: 20 }} stagger={0.04} ease="elastic.out(0.1, 0.1)" delay={1.20}>
                <SplitChars
                  wrapper={<div style={{ display: 'inline-block'}} />}
                >
                  meaningful&nbsp;
                </SplitChars>
              </Tween>
            </HeadText>
            <HeadText>
              <Tween from={{ opacity: 0, y: 20 }} stagger={0.04} ease="elastic.out(0.1, 0.1)" delay={1.6}>
                <SplitChars
                  wrapper={<div style={{ display: 'inline-block'}} />}
                >
                  design
                </SplitChars>
              </Tween>
            </HeadText>
          </Tween> */}
          <p ref={introRef}>We are busy working on our new website… But in the meantime, our temporary site is available for you to scroll through to get a look and feel about the company and see some of our previous work. You are also more than welcome to get in touch with us if you are looking for an interior design team to help you love your space and make your space home!</p>
        </div>
      </HeroCon>
    </Element>
  )
}

export default Hero
