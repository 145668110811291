import React from "react"
import styled, { keyframes } from 'styled-components'
import media from '../../styles/media'
import { Reveal, Tween } from 'react-gsap'
import Jilian from '../../images/jilian.png'

const Scale = keyframes`
  to {
    transform: scale(1.1);
  }
`

const PersonalCon = styled.div`
  display: block;
  margin: 15vh 0;
  .text {
    margin: 10vh 0;
    font-size: 14px;
    line-height: 25px;
    /* font-weight: 300; */
  }
  .image1 {
    animation: ${Scale} 1s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -1s);
    img {
      width: 90vw;
    }
  }

  ${media.laptop`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px 20px;
    margin: 25vh 5vw;
    .text {
      margin: auto 0 auto 5vw;
      font-size: 21px;
      line-height: 35px;
      /* font-weight: 300; */
      max-width: 67vw;
    }
    .image1 {
      margin: auto 0;
      img {
        width: 40vw;
        margin: 7vh 0;
      }
    }
  `}
`

const Personal = () => {
  if (typeof window !== 'undefined') {
    window.addEventListener(
      "scroll",
      () => {
        document.body.style.setProperty(
          "--scroll",
          window.pageYOffset / (document.body.offsetHeight - window.innerHeight)
        );
      },
      false
    );
  }

  return (
    <PersonalCon>
      <div className="image1">
        <img src={Jilian} alt="jilian" />
      </div>

      <div className="text">
        {/* <Reveal>
          <Tween from={{ opacity: 0, y: 20 }} duration={1}> */}
            <p>
            Its Home officially launched in 2019, but was in the heart of founder, Jillian Kay, for years. Through her studies at Inscape Design College, Jillian Kay acquired an extensive background and passion in design. Jillian started her career working for a leading homeware store in South Africa where she procured collections from all over the world where customers were able to recreate their own experiences using these pieces. In 2019 she was headhunted by an international home improvement store in South Africa to lead their décor department, this has given her the ability to uniquely tailor home improvement solutions for each customer’s specific needs and vision for their own homes. 
            </p>
          {/* </Tween>
        </Reveal> */}
        {/* <Reveal>
          <Tween from={{ opacity: 0, y: 20 }} duration={1}> */}
            <p>
            Her collective years of experience in homeware, retail and the décor industry cultivated a love and passion for all things homeware related which led to the start of ‘Its Home’.  
            </p>
          {/* </Tween>
        </Reveal> */}
        {/* <Reveal>
          <Tween from={{ opacity: 0, y: 20 }} duration={1}> */}
            <p>
            Its Home has one directive in mind, ‘love your space’. We want to make our clients love where they live by beautifully curating their spaces to be both functional and comforting. We want to create a space that both you and your family will love sharing and creating memories – a place that you can call your home. We hope that we can share our knowledge, experience and love for décor through our journey together and inspire and encourage you to curate a home that you will love too. 
            </p>
          {/* </Tween>
        </Reveal> */}
      </div>
    </PersonalCon>
  )
}

export default Personal
