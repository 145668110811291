import React from "react"
import ContactDetails from "./contactDetails"
import ContactHero from "./hero"

const ContactPage = () => (
  <>
  <ContactHero />
  <ContactDetails />
  </>
)

export default ContactPage
