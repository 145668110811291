import React from "react"
import styled from 'styled-components'
import { HeadText } from "../../styles"
import media from '../../styles/media'
import * as Scroll from "react-scroll"
import { Reveal, Tween, SplitChars } from 'react-gsap'

const ContactHeroCon = styled.div`
  padding-top: 10vh;

  p {
    &.top {
      font-size: 12px;
      margin: 0;
    }
    font-size: 14px;
    line-height: 25px;
    /* font-weight: 300; */
  }

  ${media.laptop`
    margin: 35vh 5vw 15vh;
    padding: 0;
    p {
      &.top {
        font-size: 14px;
        text-transform: uppercase;
        margin: 0;
      }
      line-height: 30px;
      /* font-weight: 300; */
    }
    h1 {
      max-width: 850px;
    }
  `}
`

const ContactHero = () => {
  const Element = Scroll.Element;

  return (
    <Element name="contact">
      <ContactHeroCon>
        <div className="text">
          {/* <Reveal>
            <Tween from={{ opacity: 0, y: 20 }} duration={1}> */}
              <p className="top">Contact</p>
            {/* </Tween>
          </Reveal> */}
          {/* <Reveal> */}
            <HeadText>Let’s work together, contact us</HeadText>
              {/* <Tween from={{ opacity: 0, y: 20 }} stagger={0.04} ease="elastic.out(0.1, 0.1)">
                <SplitChars
                  wrapper={<div style={{ display: 'inline-block'}} />}
                >
                  Let's&nbsp;
                </SplitChars>
              </Tween>
            </HeadText>
            <HeadText>
              <Tween from={{ opacity: 0, y: 20 }} stagger={0.04} ease="elastic.out(0.1, 0.1)" delay={0.2}>
                <SplitChars
                  wrapper={<div style={{ display: 'inline-block'}} />}
                >
                  work&nbsp;
                </SplitChars>
              </Tween>
            </HeadText>
            <HeadText>
              <Tween from={{ opacity: 0, y: 20 }} stagger={0.04} ease="elastic.out(0.1, 0.1)" delay={0.36}>
                <SplitChars
                  wrapper={<div style={{ display: 'inline-block'}} />}
                >
                  together,&nbsp;
                </SplitChars>
              </Tween>
            </HeadText>
            <br/>
            <HeadText>
              <Tween from={{ opacity: 0, y: 20 }} stagger={0.04} ease="elastic.out(0.1, 0.1)" delay={0.68}>
                <SplitChars
                  wrapper={<div style={{ display: 'inline-block'}} />}
                >
                  contact&nbsp;
                </SplitChars>
              </Tween>
            </HeadText>
            <HeadText>
              <Tween from={{ opacity: 0, y: 20 }} stagger={0.04} ease="elastic.out(0.1, 0.1)" delay={0.96}>
                <SplitChars
                  wrapper={<div style={{ display: 'inline-block'}} />}
                >
                  us&nbsp;
                </SplitChars>
              </Tween>
            </HeadText> */}
          {/* </Reveal> */}
        </div>
      </ContactHeroCon>
    </Element>
  )
}

export default ContactHero
