import React from "react"
import Hero from "./hero"
import GalleryMob2 from "./mobGallery2"
import Personal from "./personal"

const AboutPage = () => (
  <>
  <Hero />
  <Personal />
  <GalleryMob2 />
  </>
)

export default AboutPage
