import React from "react"
import AboutPage from "../components/About"
import ContactPage from "../components/Contact"
import HomePage from "../components/Home"
import Helmet from 'react-helmet'

import Layout from "../components/layout"
import SEO from "../components/seo"
import SocialImg from '../images/social.png'

const IndexPage = () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <meta property="og:title" content="Its Home"/>
      <meta property="og:description" content="Boutique interior design studio."/>
      <meta property="og:image" content={SocialImg}/>
    </Helmet>
    <SEO title="Home" />
    <HomePage />
    <AboutPage />
    <ContactPage />
  </Layout>
)

export default IndexPage
