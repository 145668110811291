import styled from 'styled-components'
import media from './media'

// HEADER
export const HeadText = styled.h1`
  font-size: 36px;
  line-height: 44px;
  font-family:"freight-display-pro";
  color: #D1A577;
  letter-spacing: -0.03em;
  padding: 0;
  margin: 0;
  display: inline-block;

  ${media.laptop`
    font-size: 90px;
    line-height: 100px;
  `}
`