import React from "react"
import styled, { keyframes } from 'styled-components'
import { graphql, useStaticQuery } from "gatsby"
import Img from 'gatsby-image'
import media from "../../styles/media"

const Scale = keyframes`
  to {
    transform: scaleY(1.2);
  }
`

const GalleryCon = styled.div`
  display: grid;
  grid-template-columns: 0.1fr 1fr 0.1fr;
  grid-template-rows: 0.4fr 0.1fr 0.5fr;
  margin: 0 0 25vh;
  .image1 {
    grid-column: 1/4;
    grid-row: 1/3;
    /* animation: ${Scale} 1s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -1s); */
  }
  .image2 {
    grid-column: 2/3;
    grid-row: 2/4;
    min-height: 200px;
    /* animation: ${Scale} 1s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -1s); */
    margin-bottom: 10vh;
  }

  ${media.laptop`
    display: none;
  `}
`

const GalleryMob2 = () => {
  const data = useStaticQuery(graphql`
    query {
      gallery1: file(relativePath: { eq: "look2.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery2: file(relativePath: { eq: "look4.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (typeof window !== 'undefined') {
    window.addEventListener(
      "scroll",
      () => {
        document.body.style.setProperty(
          "--scroll",
          window.pageYOffset / (document.body.offsetHeight - window.innerHeight)
        );
      },
      false
    );
  }

  return (
    <GalleryCon>
      <div className="image1">
        <Img
          durationFadeIn={250}
          objectFit="contain"
          fluid={data.gallery1.childImageSharp.fluid}
        />
      </div>
      <div className="image2">
        <Img
          durationFadeIn={250}
          objectFit="contain"
          fluid={data.gallery2.childImageSharp.fluid}
        />
      </div>
    </GalleryCon>
  )
}

export default GalleryMob2
